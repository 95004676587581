<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2"
          y="4"
          width="14"
          height="11"
          rx="1.5"
          stroke="#9e9e9e"
          stroke-width="2"
    />
    <path d="M2.875 4.917l5.457 4.9a1 1 0 001.336 0l5.457-4.9" stroke="#9e9e9e" stroke-width="2" />
  </svg>
</template>

<script>
    export default {
        name: "Mail"
    }
</script>
